.ant-layout {
	background-color: #ffffff !important;
}
.d-inline-block {
	display: inline-block;
}
.fl-left {
	float: left;
}
.fl-right {
	float: right;
}
.pos-relative {
	position: relative;
}
.abs-right {
	position: absolute;
	top: 0;
	right: 0;
}
.d-none {
display: none !important;
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
}

.flex-align-self-stretch {
	align-self: stretch;
}
.main-header {
	white-space: nowrap;
	height: 100%;
	overflow: hidden;
}
.sider-menu-logo, .header-logo {
	overflow: hidden;
	text-overflow: clip;
	white-space: nowrap;
}
.sider-menu-logo .logo {
	height: 64px;
	padding: 10px 16px;
	pointer-events: none;
}
.sider-menu-logo h1 {
	display: inline-block;
	margin: 0 0 0 -10px;
	color: #FFFFFF;
	font-weight: 600;
	font-size: 20px;
	vertical-align: middle;
	animation: fade-in;
	animation-duration: .4s;
	transition: all .4s;
	opacity: 1;
}
.header-logo {
	margin-right: 24px;
}
.header-logo .logo {
	height: 64px;
	padding: 6px 6px 6px 0px;
}
@media (min-width: 576px) {
	.header-logo .logo {
		padding-left: 15px;
	}
}
.header-logo h1 {
	color: #ffffff;
	display: inline-block;
	margin: 0 0 0 0;
	font-weight: 600;
	font-size: 16px;
	vertical-align: middle;
	animation: fade-in;
	animation-duration: .4s;
	transition: all .4s;
	opacity: 1;
}
.header-menu.ant-menu-horizontal .ant-menu-submenu-selected {
	/*background-color: #DCD1B5;*/
	background-color: #BDAB78;
}
.ant-menu-submenu-title .anticon.header-menu-arrow {
	font-size: 11px;
	margin-right: 0;
}
.app-header.ant-layout-header .trigger {
	color: #ffffff;
	margin-right: 24px;
}
.ant-badge.menu-dash-badge {
	position: absolute;
	top: 12px;
	margin-left: -4px;
}
.ant-badge.menu-dash-badge .ant-badge-count {
	background-color: #fff;
	color: #888;
	box-shadow: 0 0 0 1px #d9d9d9 inset;
	font-size: 10px;
	min-width: 15px;
	height: 15px;
	line-height: 15px;
	padding: 0 2px;
}
.header-menu.ant-menu-horizontal .ant-menu-submenu-title,
.header-menu.ant-menu-horizontal .ant-menu-submenu-title:hover {
	color: #ffffff;
}
.ant-layout-sider-collapsed .sider-menu-logo h1 {
	max-width: 0;
	opacity: 0;
}
.ant-menu.sider-menu {
	margin-top: 12px;
}
.ant-menu-item .favourite-star.anticon,
.favourite-star.anticon {
	margin-right: 0;
	font-size: 10px;
	color: #FADB14 !important;
}

.ant-layout-header.app-header {
	/*background-color: #ffffff;*/
	padding: 0 15px;
  	position: fixed;
	z-index: 10;
	width: 100%;
}
.app-content {
	margin-top: 64px;
}
@media (max-width: 576px) {
	.ant-layout-header.app-header {
		position: static;
	}
	.app-content {
		margin-top: 0;
	}
}
.ant-breadcrumb {
	padding: 5px 0 15px 0!important;
}


.page-form-container {
	margin-top: 50px;
	max-width: 320px;
	margin: 100px auto 0;
	padding: 10px 20px;
	background-color: #fff;
}
.ant-layout .ant-layout-footer {
	text-align: center;
	/*background: transparent !important;*/
	/*background: #BDAB78;*/
	background: #000000;
	color: #fff !important;
	white-space: nowrap;
	font-size: 12px;
	/*position: fixed;
	width: 100%;
	bottom: 0;*/
}
.ant-layout-sider-collapsed + .ant-layout .ant-layout-footer {
	white-space: normal;
}
.favourite-trigger {
	padding: 0;
}
.favourite-trigger .ant-rate {
	font-size: 18px;
}
.ant-layout-sider .user-info {
	margin: 20px 0 10px;
	color: rgba(255, 255, 255, 0.65);
	cursor: pointer;
}
.ant-layout-sider .user-name {
	max-width: 190px;
}
.ant-layout-sider .user-info .ant-dropdown-trigger {
	padding: 0 0 6px;
	border-bottom: 2px solid transparent;
	transition: border 0.5s;
}
.ant-layout-sider .user-info .ant-dropdown-trigger.ant-dropdown-open,
.ant-layout-sider .user-info .ant-dropdown-trigger:hover {
	border-bottom: 2px solid #DCD1B5;
}
.userinfo-menu-overlay ul {
	margin-top: -3px!important;
}
.ant-layout-sider .user-name,
.ant-layout-header .user-name {
	padding-right: 10px;
}
.user-info {
	padding: 0 6px;
	text-align: left;
	font-size: 13px;
}
.user-info button { font-size: 12px; }
.user-name {
	display: inline-block;
	padding-left: 10px;
	line-height: 1.3;
	vertical-align: middle;
}
.user-name span {
	font-size: 90%;
}
.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.App main.ant-layout-content {
	padding: 15px;
}
.App .main-panel {
	background-color: #ffffff;
	padding: 15px;
	overflow-x: hidden;
}
.main-panel .ant-page-header {
	padding-left: 0;
	padding-right: 0;
}



/* hide file icon */
.ant-tree.ant-tree-show-line.hide-file-icon li span.ant-tree-switcher-noop {
  background: transparent;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:before {
  content: " ";
  width: 1px;
  border-left: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  left: 12px;
  top: 0;
  margin: 0;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:before {
  top: 6px;
  height: calc(100% - 6px);
}

.ant-tree.ant-tree-show-line.hide-file-icon li:last-child:before {
  height: 16px;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:last-child:before {
  height: 10px;
}

.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop > i {
  visibility: hidden;
}

.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop:after {
  content: " ";
  height: 1px;
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  position: absolute;
  left: 12px;
  top: 50%;
  margin: 0;
}
/* end of hide file icon */


.mt-15 {
	margin-top: 15px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-5 {
	margin-bottom: 5px!important;
}
.mb-15 {
	margin-bottom: 15px!important;
}
.mb-30 {
	margin-bottom: 30px!important;
}
.nopadding {
	padding: 0!important;
}
.logo-preview {
	display: block;
	margin-bottom: 10px;
}

.my-dashboards .ant-card-cover {
	padding: 10px 20px;
	background-color: #ababab;
	cursor: pointer;
}
.my-dashboards .ant-card-body .ant-card-meta {
	cursor: pointer;
}
.ant-list .dash-item {
	text-align: left;
	font-size: 1.5em;
	position: relative;
	/*height: 100px;*/
	padding: 15px 10px;
	overflow: hidden;
	-webkit-box-shadow: inset -1px 0px 5px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: inset -1px 0px 5px 0px rgba(0,0,0,0.3);
	box-shadow: inset -1px 0px 5px 0px rgba(0,0,0,0.3);
}
.ant-list .dash-item a {
	color: rgba(60,60,60,0.75);
	text-shadow: 0px 0px 4px #aaaaaa;
	transition: all .2s
}
.ant-list .dash-item a:hover {
	color: rgba(255,255,255,0.96);
	text-shadow: 0px 0px 9px #ffffff;
}
.ant-list .dash-item a:hover .dash-ico {
	filter: drop-shadow(0px 0px 6px #ffffff);
}
.ant-list .dash-ico {
	/*display: block;*/
	font-size: 1.5em;
	vertical-align: middle;
}
.ant-list .dash-item small {
	font-size: 11px;
	font-weight: bold;
	line-height: 1.2;
	display: inline-block;
}
.ant-list .dash-item .favourite-star {
	position: absolute;
	/*top: -1px;
	right: 50%;
	margin-right: -2.70em;*/
	top: 5px;
	right: 7px;
	font-size: 13px;
	color: white!important;
}
/* https://flatuicolors.com/palette/de */
.dash-item.col-overview, .dash-item.col-overview-offline, .dash-item.col-ecommerce {
	background-color: #3867d6;
}
.dash-item.col-overview-new {
	background-color: #2f4b7c;
}
.dash-item.col-ecommerce-new {
	background-color: #a05195;
}
.dash-item.col-display-video {
	background-color: #fc5c65;
}
.dash-item.col-display-video-pop {
	background-color: #f95d6a;
}
.dash-item.col-search,
.dash-item.col-search-loreal,
.dash-item.col-search-seo {
	background-color: #f7b731;
}
.dash-item.col-search-seo-new {
	background-color: #ffa600;
}
.dash-item.col-search-sa360-new {
	background-color: #ffa600;
}
.dash-item.col-search-gads-new {
	background-color: #ffa600;
}
.dash-item.col-social {
	background-color: #4b7bec;
}
.dash-item.col-social-new {
	background-color: #ffdb0d;
}
.dash-item.col-analytics {
	background-color: #d1d8e0;
}
.dash-item.col-analytics-new {
	background-color: #665191;
}
.dash-item.col-cos-roas {
	background-color: #778ca3;
}
.dash-item.col-tv {
	background-color: #a55eea;
}
.dash-item.col-tv-new {
	background-color: #48cfad;
}
.dash-item.col-drtv {
	background-color: #a55eea;
}
.dash-item.col-radio {
	background-color: #0fb9b1;
}
.dash-item.col-radio-new {
	background-color: #72db85;
}
.dash-item.col-press {
	background-color: #20bf6b;
}
.dash-item.col-others {
	background-color: #fd9644;
}
.dash-item.col-others-new {
	background-color: #ff7c43;
}
.dash-item.col-media-quality {
	background-color: #45aaf2;
}
.dash-item.col-investments, .dash-item.col-investments-new
,.dash-item.col-investments-24
,.dash-item.col-investments-admin {
	background-color: #BDAB78;
}
.dash-item.col-competition {
	background-color: #bf9668;
}
.dash-item.col-naming-warnings {
	background-color: #c68642;
}
.dash-item.col-naming-warnings-new {
	background-color: #00bad4;
}


.client-taxonomy-breadcrumb.ant-breadcrumb {
	margin: 0;
	padding: 0!important;
	white-space: nowrap;
}
.ant-layout-sider-collapsed + .ant-layout .client-taxonomy-breadcrumb {
	white-space: normal;
}
.my-dashboards .ant-card-small .ant-card-body {
	padding: 1px;
}
.my-dashboards .logo-container {
	position: relative;
}
.level-list .dash-count {
	position: absolute;
	right: 22%;
	top: 8px;
}
.level-list .dash-count .ant-badge-count {
	font-size: 11px;
}
.my-dashboards .logo-bg {
	background-size: 45% auto;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 116px;
	height: 100%;
	/*-webkit-box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.3);
	box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.3);*/
}
.my-dashboards .parents-tree {
	color: #9b9b9b;
	font-size: 11.5px!important;
	font-weight: normal!important;
}
.section-title {
	text-align: center;
	padding: 8px 0;
}
/*.client-taxonomy-breadcrumb .item {
	font-size: 14px;
	font-weight: bold;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	line-height: 1.1;
	position: relative;
	padding: 8px 24px 8px 12px;
}
.client-taxonomy-breadcrumb .item::after {
	content: '»';
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	right: 0;
}
.client-taxonomy-breadcrumb .item:last-child {
	padding-right: 12px;
}
.client-taxonomy-breadcrumb .item:last-child::after {
	content: '';
	font-size: 16px;
	display: none;
	font-weight: normal;
}*/
.client-taxonomy-breadcrumb .ant-breadcrumb-link {
	display: inline-block;
	vertical-align: middle;
	line-height: 1.25;
	margin-bottom: 5px;
}
.client-taxonomy-breadcrumb .ant-breadcrumb-link span {
	display: block;
	font-weight: normal;
	font-size: 11px;
	font-style: italic;
	text-align: center;
}
@media (max-width: 576px) {
	.client-taxonomy-breadcrumb .ant-breadcrumb-link span {
		display: inline;
		padding-left: 4px;
		font-size: 9px;
	}
	.client-taxonomy-breadcrumb .ant-breadcrumb-link span::before {
		content: '(';
	}
	.client-taxonomy-breadcrumb .ant-breadcrumb-link span::after {
		content: ')';
	}
}
@media screen and (max-width: 991px) {
	.ant-list .dash-ico {
		font-size: 1.75em;
	}
	/*
	.ant-list .dash-item {
		height: 85px;
	}*/
	
}

.z-10 {
	z-index: 10;
}

.take-all {
	width: 100%;
}

/* Full Background Image */
img.full-bg {
    min-height: 100%;
    min-width: 1280px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1280px) {
    img.full-bg {
        left: 50%;
        margin-left: -640px;
    }
}

.animation-pulseSlow {
    animation-name: pulseSlow;
    -webkit-animation-name: pulseSlow;
    animation-duration: 30s;
    -webkit-animation-duration: 30s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}

@keyframes pulseSlow {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@-webkit-keyframes pulseSlow {
    0% {
        -webkit-transform: scale(1.1);
    }
    50% {
        -webkit-transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.1);
    }
}

.simple-layout .title-container {
	margin: -10px -20px 30px;
	padding: 10px 20px;
	background: #BDAB78;
	text-align: center;
}
.simple-layout .title-container h2 {
	margin-bottom: 0;
	color: #fff;
	font-size: 26px;
}
.log-error {
	color: #F5222D;
}
.log-warn {
	color: #FAAD14;
}
.manage-data-tabs, .manage-data-tabs .ant-tabs-left-bar,
.export-data-tabs, .export-data-tabs .ant-tabs-left-bar
{
	min-height: 550px;
}
